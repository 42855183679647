/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Audit_Api_DTOs_AuditResponse } from '../models/Audit_Api_DTOs_AuditResponse';
import type { Audit_Api_DTOs_AuditUpdateVendorDto } from '../models/Audit_Api_DTOs_AuditUpdateVendorDto';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class UpdateVendorService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Update invoice's vendor v2.1
     * Update invoice's vendor
     * @returns Audit_Api_DTOs_AuditResponse Success
     * @throws ApiError
     */
    public putApiV21AuditsInvoiceUpdateVendor({
        auditUuid,
        invoiceUuid,
        requestBody,
    }: {
        auditUuid: string,
        invoiceUuid: string,
        requestBody?: Audit_Api_DTOs_AuditUpdateVendorDto,
    }): Observable<Audit_Api_DTOs_AuditResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2.1/audits/{auditUuid}/invoice/{invoiceUuid}/update-vendor',
            path: {
                'auditUuid': auditUuid,
                'invoiceUuid': invoiceUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
}
