/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Api_DTO_BusinessRuleAssignRequest } from '../models/Invoice_Api_DTO_BusinessRuleAssignRequest';
import type { Invoice_Api_DTO_BusinessRuleAssignResponse } from '../models/Invoice_Api_DTO_BusinessRuleAssignResponse';
import type { Invoice_Api_DTO_BusinessRuleCreateRequest } from '../models/Invoice_Api_DTO_BusinessRuleCreateRequest';
import type { Invoice_Api_DTO_BusinessRuleGetResponse } from '../models/Invoice_Api_DTO_BusinessRuleGetResponse';
import type { Invoice_Api_DTO_BusinessRuleUnassignRequest } from '../models/Invoice_Api_DTO_BusinessRuleUnassignRequest';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class BusinessRulesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Assigns business rule to a tenant and a vendor/customer combination
     * Assigns business rule to a tenant and a vendor/customer combination
     * @returns Invoice_Api_DTO_BusinessRuleAssignResponse Success
     * @throws ApiError
     */
    public postApiV21BusinessRulesAssign({
        requestBody,
    }: {
        requestBody?: Invoice_Api_DTO_BusinessRuleAssignRequest,
    }): Observable<Array<Invoice_Api_DTO_BusinessRuleAssignResponse>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2.1/business-rules/assign',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Unassigns business rule from a tenant or a vendor/customer combination
     * Unassigns business rule from a tenant or a vendor/customer combination
     * @returns void
     * @throws ApiError
     */
    public putApiV21BusinessRulesUnassign({
        requestBody,
    }: {
        requestBody?: Invoice_Api_DTO_BusinessRuleUnassignRequest,
    }): Observable<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2.1/business-rules/unassign',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Get business rule of a tenant and a tenant's vendor/customer combination
     * Get business rule of a tenant and a tenant's vendor/customer combination
     * @returns Invoice_Api_DTO_BusinessRuleGetResponse Success
     * @throws ApiError
     */
    public getApiV21BusinessRules({
        xTenantUuid,
    }: {
        xTenantUuid?: string,
    }): Observable<Array<Invoice_Api_DTO_BusinessRuleGetResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.1/business-rules',
            headers: {
                'X-Tenant-Uuid': xTenantUuid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Create business rule
     * Create business rule
     * @returns any Success
     * @throws ApiError
     */
    public postApiV21BusinessRules({
        requestBody,
    }: {
        requestBody?: Invoice_Api_DTO_BusinessRuleCreateRequest,
    }): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2.1/business-rules',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
}
