import {
  EnvironmentProviders,
  Provider,
  makeEnvironmentProviders
} from '@angular/core'

import { AngularHttpRequest } from './platform-v2_1/core/AngularHttpRequest'
import { platform_v2_1, businessrules_v2_1 } from './'

export function provideServicesApiCodegen(host: string): EnvironmentProviders {
  console.log('providers')
  const value = {
    BASE: host
  }

  const openApiProviders: Array<Provider> = [
    platform_v2_1,
    businessrules_v2_1
  ].flatMap(provider => [
    {
      provide: provider.OpenAPI,
      useValue: value
    },
    {
      provide: provider.BaseHttpRequest,
      useClass: AngularHttpRequest
    }
  ])

  return makeEnvironmentProviders(openApiProviders)
}
