/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Navix_Services_Sdk_Order } from '../models/Navix_Services_Sdk_Order';
import type { Navix_Services_Sdk_OrderSearchCriteriaDTO } from '../models/Navix_Services_Sdk_OrderSearchCriteriaDTO';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class OrdersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Search for Orders given search criteria.
     * This endpoint returns a list of order UUIDs that match the search criteria.
     * @returns Navix_Services_Sdk_Order Success
     * @throws ApiError
     */
    public ordersSearch({
        requestBody,
    }: {
        requestBody?: Navix_Services_Sdk_OrderSearchCriteriaDTO,
    }): Observable<Array<Navix_Services_Sdk_Order>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2.1/orders/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }
}
