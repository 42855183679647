/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Navix_Services_Sdk_Document } from '../models/Navix_Services_Sdk_Document';
import type { Navix_Services_Sdk_DocumentCreationDTO } from '../models/Navix_Services_Sdk_DocumentCreationDTO';
import type { Navix_Services_Sdk_DocumentRelation } from '../models/Navix_Services_Sdk_DocumentRelation';
import type { Navix_Services_Sdk_DocumentRelationDTO } from '../models/Navix_Services_Sdk_DocumentRelationDTO';
import type { Navix_Services_Sdk_DocumentUpdateDTO } from '../models/Navix_Services_Sdk_DocumentUpdateDTO';
import type { Navix_Services_Sdk_SearchRequestDTO } from '../models/Navix_Services_Sdk_SearchRequestDTO';
import type { Navix_Services_Sdk_SearchResponseDTO } from '../models/Navix_Services_Sdk_SearchResponseDTO';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns Navix_Services_Sdk_Document Success
     * @throws ApiError
     */
    public getApiV21Documents({
        uuid,
        xTenantUuid,
    }: {
        uuid: string,
        xTenantUuid?: string,
    }): Observable<Navix_Services_Sdk_Document> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.1/documents/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'X-Tenant-Uuid': xTenantUuid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @returns Navix_Services_Sdk_Document Success
     * @throws ApiError
     */
    public putApiV21Documents({
        uuid,
        xTenantUuid,
        xCorrelationId,
        requestBody,
    }: {
        uuid: string,
        xTenantUuid?: string,
        xCorrelationId?: string,
        requestBody?: Navix_Services_Sdk_DocumentUpdateDTO,
    }): Observable<Navix_Services_Sdk_Document> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2.1/documents/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'X-Tenant-Uuid': xTenantUuid,
                'X-Correlation-Id': xCorrelationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @returns Navix_Services_Sdk_Document Success
     * @throws ApiError
     */
    public getApiV21DocumentsTenants({
        tenantUuid,
    }: {
        tenantUuid: string,
    }): Observable<Array<Navix_Services_Sdk_Document>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2.1/documents/tenants/{tenantUuid}',
            path: {
                'tenantUuid': tenantUuid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @returns Navix_Services_Sdk_SearchResponseDTO Success
     * @throws ApiError
     */
    public postApiV21DocumentsSearch({
        requestBody,
    }: {
        requestBody?: Navix_Services_Sdk_SearchRequestDTO,
    }): Observable<Navix_Services_Sdk_SearchResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2.1/documents/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @returns Navix_Services_Sdk_Document Success
     * @throws ApiError
     */
    public postApiV21Documents({
        xTenantUuid,
        xCorrelationId,
        requestBody,
    }: {
        xTenantUuid?: string,
        xCorrelationId?: string,
        requestBody?: Navix_Services_Sdk_DocumentCreationDTO,
    }): Observable<Navix_Services_Sdk_Document> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2.1/documents',
            headers: {
                'X-Tenant-Uuid': xTenantUuid,
                'X-Correlation-Id': xCorrelationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @returns Navix_Services_Sdk_DocumentRelation Success
     * @throws ApiError
     */
    public patchApiV21DocumentsRelations({
        uuid,
        xTenantUuid,
        xCorrelationId,
        requestBody,
    }: {
        uuid: string,
        xTenantUuid?: string,
        xCorrelationId?: string,
        requestBody?: Array<Navix_Services_Sdk_DocumentRelationDTO>,
    }): Observable<Array<Navix_Services_Sdk_DocumentRelation>> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2.1/documents/{uuid}/relations',
            path: {
                'uuid': uuid,
            },
            headers: {
                'X-Tenant-Uuid': xTenantUuid,
                'X-Correlation-Id': xCorrelationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
}
