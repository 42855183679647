/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Audit_Api_DTOs_AuditChargesRequestDto } from '../models/Audit_Api_DTOs_AuditChargesRequestDto';
import type { Navix_Services_Sdk_Audit } from '../models/Navix_Services_Sdk_Audit';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class NewChargesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Update the invoice charges for an audit
     * This endpoint updates the charges adjustments for an invoice. Currently it updates the invoice charges tables and the Audit Document invoice charges adjustments
     * @returns Navix_Services_Sdk_Audit Success
     * @throws ApiError
     */
    public putApiV21AuditsInvoiceCharges({
        auditUuid,
        invoiceUuid,
        requestBody,
    }: {
        auditUuid: string,
        invoiceUuid: string,
        requestBody?: Audit_Api_DTOs_AuditChargesRequestDto,
    }): Observable<Navix_Services_Sdk_Audit> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2.1/audits/{auditUuid}/invoice/{invoiceUuid}/charges',
            path: {
                'auditUuid': auditUuid,
                'invoiceUuid': invoiceUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }
}
